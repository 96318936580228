.search-result-wrapper {
  margin-top: 10px;
}

.search-result-wrapper .search-result-title {
  font-size: 20px;
}

.search-result-wrapper .search-table thead tr th {
    font-weight: 100;
    color: #003767;
}

.search-result-wrapper .search-table tbody tr .borderless {
    border: transparent;
}

.search-result-wrapper .search-table tbody tr .borderless-right {
    border-right: transparent;
}

.spinner-container{
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top{
  width: 50px;
  height: 50px;
  background-color: #003767;
  color: white;
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;

  position: fixed;
  bottom: 10px;
  right: 10px;
}

.search-image {
  max-width: 10rem;
  height: auto;
  width: auto;
  max-height: 10rem;
}