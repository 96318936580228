.App {
  max-width: 100vw;
  min-height: 100vh;
  height: 100%;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  text-align: left;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px 20px;
}

main {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  flex: 1 0 300px;
  gap: 300px;
}

.main-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  margin-bottom: 10px;

  flex-basis: 70%;

  position: relative;
}

p {
  margin: 0 !important;
}

.text-alt {
  font-family: "Source Sans Pro" sans-serif;
}

.tooltip-btn {
  border: none;
  cursor: pointer;
  background-color: white;
  padding: 0;
}

.question-toolti {
  position: absolute;
}

.question-tooltip .tooltip-inner {
  background-color: white !important;
  color: black !important;
  border: 1px solid rgb(223, 223, 223);
}

.question-tooltip .tooltip-arrow {
  display: none !important;
}

@media (min-width: 320px) {
}

@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width: 1025px) {
  .App {
    padding: 20px 50px;
  }

  main {
    flex-direction: row;
  }

  main .main-container {
    margin-right: 30px;
  }
}

@media (min-width: 1281px) {
  .App {
    padding: 20px 100px;
  }
}

@media (min-width: 1900px) {
  .App {
    padding: 20px 200px;
  }

  .main-container {
    flex-basis: 80%;
  }
}
