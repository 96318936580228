.search-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 30px 0 10px;
}

.search-field input {
    width: 400px;
    height: 35px;

    border: 0;
    border-radius: 2px;

    background-color: rgb(187, 187, 187);
}

.search-field .search-btn {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 35px;

    padding: 5px 20px;

    border: 0;
    border-radius: 0 5px 5px 0;

    background-color: #003767;
    color: white;
}