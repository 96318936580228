.sidebar-wrapper {
  max-width: 250px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.filter-button {
  display: flex;
  justify-content: left;
  padding-left: 5px;
  padding-right: 5px;
  text-transform: none;
}
