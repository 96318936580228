.categorization-wrapper .category {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  margin-bottom: 20px;
}

.categorization-wrapper .category-other-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.categorization-wrapper .categorization-title {
  font-size: 20px;
}

.categorization-wrapper .category .category-item {
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 5px 10px;
  border: 1px solid #dee2e6;
}

.categorization-wrapper .category .category-item .category-item-text {
  color: #003767;
}

.json-field {
  background-color: #f6f6f6;
  padding: 6px;
  border-radius: 5px;
  color: #2f3337;
  font-family: monospace;
  overflow-x: auto;
}

.null-object {
  background-color: #f6f6f6;
  padding: 6px;
  border-radius: 5px;
  color: #2f3337;
  width: 160px;
  height: 80px;
  font-family: monospace;
}

.show-more {
  display: block;
  color: #003767;
  margin-bottom: 12px;
  cursor: pointer;
}
